import React from 'react'

const ProductDiscount = ({ discount, prefix = '' }) => {
  const roundToNearest5 = (x) => Math.round(x / 5) * 5

  return (
    !!discount && (
      <span className="pill is-sm md:ml-2 -mb-1">
        {prefix}
        {roundToNearest5(discount)}% OFF
      </span>
    )
  )
}

export default ProductDiscount
