import React, { useState, useRef } from 'react'
import { m } from 'framer-motion'
import cx from 'classnames'

import { buildShopifySrc, useWindowSize } from '@lib/helpers'
import CustomLink from '@components/link'
import { ProductPrice } from '@components/product'
import ProductDiscount from './product/product-discount'
import Photo from './photo'
import { useIntersection } from 'use-intersection'

const itemAnim = {
  show: {
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: 'linear',
    },
  },
  hide: {
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: 'linear',
    },
  },
}

const ProductCard = ({ product, className }) => {
  
  if (!product) return null
  
  const [hovering, setHovering] = useState(false)
  const windowSize = useWindowSize()
  const link = { page: product }
  const hoverPhoto =
    product?.altPhotos?.find((p) => p.forOption === 'Thumbnail:Hover') ||
    (product?.gallery || [])[0]

  const imgRef = useRef()
  const isIntersecting = useIntersection(imgRef, {
    once: true,
    rootMargin: '200px',
  })

  return (
    <m.div
      variants={itemAnim}
      className={cx('product-card', `is-${product.category.slug}`, className)}
      onMouseEnter={() => setHovering(true)}
    >
      <div className="product-card--container photo-dropshadow">
        {hoverPhoto && (
          <Photo
            photo={hoverPhoto.photo}
            className="product-card--image-hover"
            layout={
              product.category.slug === 'frames'
                ? 'product-card-frames-hover'
                : 'product-card-hover'
            }
            hasPlaceholder={false}
            init={hovering}
          />
        )}
        <CustomLink
          link={link}
          className="product-card--link"
          aria-label={`Go to ${product.title}`}
        >
          <></>
        </CustomLink>
        <div>
          <div className="product-card--content" ref={imgRef}>
            {product?.photo && isIntersecting && (
              <img loading='lazy' src={buildShopifySrc(product.photo, 'product-card', windowSize)} />
            )}
          </div>
        </div>
      </div>
      <p className="product-card--text">
        <CustomLink link={link} />
        {product?.artist && (
          <>
            <br />
            <CustomLink link={{ page: product.artist }} className="underline" />
          </>
        )}
        <br />
        <ProductPrice prefix="From " item={product} />
        <br className="block md:hidden" />
        <ProductDiscount prefix="Up to " discount={product.discounted} />
      </p>
    </m.div>
  )
}

export default ProductCard
