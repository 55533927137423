import React from 'react'

import Slider from '@components/slider'
import ProductCard from '@components/product-card'
import CustomLink from '@components/link'

const ProductSlider = ({
  data: { source, label, title, products = [], link },
}) => {
  if (!(products?.length > 0)) return null

  const getLabel = () => {
    return label || source
  }

  return (
    <section className="product-slider">
      <div className="heading">
        {getLabel() && <div className="pill is-sm">{getLabel()}</div>}
        <div className="product-slider--title">
          <h3 className="heading--title">
            {link ? (
              <CustomLink link={{ ...link, title: null }}>{title}</CustomLink>
            ) : (
              title
            )}
          </h3>
          {link && <CustomLink link={link} className="uppercase" />}
        </div>
      </div>
      <Slider>
        {products.map((product, key) => (
          <ProductCard key={key} product={product} />
        ))}
      </Slider>
    </section>
  )
}

export default ProductSlider
